<template>
    <component :is="resolveLayoutVariant"
               :class="`skin-variant--${appSkinVariant}`">
        <transition :name="appRouteTransition"
                    mode="out-in"
                    appear>
            <div>
                <router-view :loading="loading"
                             @loading-updated="updateLoading" />
                <loader :visible="loading"></loader>
            </div>

        </transition>
    </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

import loader from './views/loader.vue'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import useDynamicVh from '@core/utils/useDynamicVh'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
// Dynamic vh

export default {
    components: {
        LayoutContentVerticalNav,
        LayoutBlank,
        loader
    },
    data()
    {
        return {
            loading: false,
        };
    },
    methods: {
        updateLoading(loading)
        {
            
            this.loading = loading;
        },
    },

    setup()
    {
        const { route } = useRouter()
        const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

        const { handleBreakpointLayoutSwitch } = useLayout()
        handleBreakpointLayoutSwitch()

        const resolveLayoutVariant = computed(() =>
        {
            if (route.value.meta.layout === 'blank') return 'layout-blank'
            if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

            return null
        })

        useDynamicVh()

        return {
            resolveLayoutVariant,
            appSkinVariant,
            appRouteTransition,
        }
    },
}

// site key: 6LfVsEsmAAAAABjGuxErkU6xo59Exwcff3IoBaPy
//Secret key: 6LfVsEsmAAAAAEm200vWnCu_si7z3Pu1h5fJNwDI
</script>
