// import Vue from 'vue';
// import Vuex from 'vuex';
// import pathify from 'vuex-pathify';
// import createPersistedState from 'vuex-persistedstate';

// import { modules as module } from './modules';
// import app from './app';
// import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'

// Vue.use(Vuex);
// const store = new Vuex.Store({
//   state: {},
//   mutations: {},
//   actions: {},
//   plugins: [pathify.plugin, createPersistedState({ storage: window.localStorage, paths: ['accounts'] })],
//   modules: {
//     appConfig: appConfigStoreModule,
//     app,
//     module
//   },
// });

// export default store;


import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },
})

export default store;