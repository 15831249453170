<template>
    <v-menu offset-y
            nudge-bottom="22"
            min-width="175"
            left
            :elevation="$vuetify.theme.dark ? 9 : 8">
        <!-- Activator -->
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs"
                 class="d-flex align-center mr-3 arabic"
                 v-on="on"
                 @click="updateActiveLocale">
                 
                <v-img v-if="locales[0].title == 'en'"
                       :src="require(`@/assets/images/flags/en.png`)"
                       :alt="$i18n.locale"
                       height="14px"
                       width="22px"
                       class="me-2"></v-img>
                <v-img v-if="locales[0].title == 'ar'"
                       :src="require(`@/assets/images/flags/ar.png`)"
                       :alt="$i18n.locale"
                       height="14px"
                       width="22px"
                       class="me-2"></v-img>

                <span v-bind:class="{ 'text-black': !submitEmail, 'text_white': submitEmail}"
                      style="font-size: 16px;"
                      v-if="$vuetify.breakpoint.smAndUp && locales[0].title == 'en'">{{ locales[0].title }}</span>

                <span v-bind:class="{ 'text-black': !submitEmail, 'text_white': submitEmail }"
                      style="font-size: 16px;font-family: 'Frutiger LT Arabic'"
                      v-if="$vuetify.breakpoint.smAndUp && locales[0].title == 'ar'">{{ locales[1].title }}</span>
            </div>
        </template>

        <!-- Options List -->
        <!-- <v-list>
      <v-list-item-group
        :value="$i18n.locale"
        @change="updateActiveLocale"
      >
        <v-list-item
          v-for="locale in locales"
          :key="locale.locale"
          :value="locale.locale"
        >
          <v-img
            :src="locale.img"
            height="14px"
            width="22px"
            :alt="locale.locale"
            class="me-2"
          ></v-img>
          <v-list-item-title>{{ locale.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list> -->
    </v-menu>
</template>

<script>
import { loadLanguageAsync } from '@/plugins/i18n'
import useAppConfig from '@core/@app-config/useAppConfig'

export default {
    props: {
        submitEmail: {
            default: true,
            type: Boolean
        },
    },
    setup()
    {
        const { isRtl } = useAppConfig()
        var currentLangTemp = 'العربية'
        var currentLang = 'en'

        const locales = [
            {
                title: 'ar',
                img: require('@/assets/images/flags/en.png'),
                locale: 'en',
            },
            {
                title: 'ar',
                img: require('@/assets/images/flags/ar.png'),
                locale: 'ar',
            },
        ]

        var lang = localStorage.getItem('language');

        if (lang)
        {
            if (lang == 'ar')
            {
                loadLanguageAsync(lang);
                currentLang = lang;
                locales[0].title = 'en';
                currentLangTemp = 'English';
                localStorage.setItem('language', 'ar');
                isRtl.value = true
            }
            else
            {
                loadLanguageAsync(lang);
                currentLang = lang;
                locales[0].title = 'ar';
                currentLangTemp = 'العربية';
                localStorage.setItem('language', 'en');
                isRtl.value = false
            }
        }
        else
        {
            localStorage.setItem('language', 'en')
        }

        const updateActiveLocale = locale =>
        {
            if (locales[0].title == 'ar')
            {
                isRtl.value = true
                locales[0].title = 'en';
                currentLangTemp = 'English'
                loadLanguageAsync('ar');
                localStorage.setItem('language', 'ar')
            }
            else
            {
                isRtl.value = false
                locales[0].title = 'ar';
                currentLangTemp = 'العربية'
                loadLanguageAsync('en');
                localStorage.setItem('language', 'en')
            }
            // currentLang = store.get('accounts/language');
            // Set to RTL mode if locale is arabic
        }

        return {
            locales,
            currentLangTemp,
            currentLang,
            updateActiveLocale,
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.text_white {
  color: white;
}</style>
