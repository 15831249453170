import { mdiAccountBox, mdiAccountGroup, mdiCalendarMonth, mdiSailBoat, mdiWallet } from '@mdi/js';

let role_name = localStorage.getItem('role_name');

let admin = [
    { subheader: 'MENU' },
    {
        title: 'Dashboard', icon: mdiSailBoat, to: 'dashboard'

    },
    {
        title: 'Bookings', icon: mdiCalendarMonth, to: 'booking-list'

    },
    {
        title: 'Boat Registration', icon: mdiSailBoat, to: 'myboat-list'

    },
    {
        title: 'Packages', icon: mdiAccountBox, to: 'package-list'

    },
    {
        title: 'Users Management', icon: mdiAccountGroup,
        children: [
            { title: 'Admins', to: 'admin-list', },
            { title: 'Boat Owners', to: 'owner-list', },
            { title: 'App Users', to: 'user-list', },
            //   { title: 'Add/Update', to: { name: 'user-form', params: { id: 'add' } } }
        ],
    },
   
    {
        title: 'Finance', icon: mdiWallet, to: 'finance'

    },
    // {
    //     title: 'My Profile', icon: mdiAccountBox, to: ''

    // },
    
      {
        title: 'System Parameters', icon: mdiSailBoat, to: 'system-parameter'

      },
]


let boatOwner = [
    { subheader: 'MENU' },
    {
        title: 'Dashboard', icon: mdiSailBoat, to: 'dashboard'

    },
    {
        title: 'Bookings', icon: mdiCalendarMonth, to: 'booking-list'

    },
    {
        title: 'Boat Registration', icon: mdiSailBoat, to: 'myboat-list'

    },
    {
        title: 'Packages', icon: mdiAccountBox, to: 'package-list'

    },
]

let initiator = [
    { subheader: 'MENU' },
    {
        title: 'Boat Registration', icon: mdiSailBoat,
        children: [
            { title: 'List', to: 'myboat-list', },
            { title: 'Add', to: { name: 'myboat-add' } }
        ],
    },
]

let approver = [
    { subheader: 'MENU' },
    {
        title: 'Boat Registration', icon: mdiSailBoat,
        children: [
            { title: 'List', to: 'myboat-list' }
        ],
    },
]
let menus = [];

if (role_name == 'admin')
{
    menus = admin
}
else if (role_name == 'initiator')
{
    menus = initiator
}
else if (role_name == 'boat_owner')
{
    menus = boatOwner
}
else
{
    menus = approver
}

export default menus
