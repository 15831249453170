// import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import App from './App.vue'
import './plugins/acl'
import sweetalert2 from './plugins/sweet-alert'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import mask from './plugins/mask'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  mask,
  vuetify,
  sweetalert2,
  VuePhoneNumberInput,
  render: h => h(App),
}).$mount('#app')
