import Vue from 'vue'
import VueRouter from 'vue-router'

// import dashboard from './dashboard'

Vue.use(VueRouter)

const routes = [
    // ? We are redirecting to different pages based on role.
    // NOTE: Role is just for UI purposes. ACL is based on abilities.
    // {
    //     path: '/',
    //     redirect: to =>
    //     {
    //         const userData = JSON.parse(localStorage.getItem('userData'))
    //         const role = JSON.parse(localStorage.getItem('role'))
    //         const userRole = role && role.id ? role.id : null

    //         console.log(userRole)
    //         console.log(role && role.id)

    //         if (userRole === 1) return { name: 'myboat-list' }

    //         // if (userRole === 'client') return { name: 'page-access-control' }

    //         return { name: 'auth-login', query: to.query }
    //     },
    // },
    // {
    //     path: '/error-404',
    //     name: 'error-404',
    //     component: () => import('@/views/Error404.vue'),
    //     meta: {
    //         layout: 'blank',
    //         resource: 'Public',
    //     },
    // },
    {
        path: '/',
        name: 'register',
        component: () => import('@/views/BoatRegisterApp.vue'),
        meta: {
            layout: 'blank',
            resource: 'Public'
        },
    },
    
    // {
    //     path: '/',
    //     name: 'register',
    //     component: () => import('@/views/Captcha.vue'),
    //     meta: {
    //         layout: 'blank',
    //         resource: 'Public'
    //     },
    // },
]
    // {
    //   path: '*',
    //   redirect: 'error-404',
    // }
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior()
    {
        return { x: 0, y: 0 }
    },
})

export default router
