export default {
    // Template
    'MENU': 'MENU',
    'Boat Registration': 'Boat Registration',
    'Users Management': 'Users Management',
    'Logout': 'Logout',

    'Marina': 'Marina',
    'Booking Type': 'Booking Type',
    'Day Range': 'Day Range',
    'password_info': 'Password must be 6 characters long.',

    'Add New Admin': 'Add New Admin',
    'Update Admin': 'Update Admin',
    'User Details': 'User Details',
    'Name': 'Name',
    'Email ID': 'Email ID',
    'Contact Number': 'Contact Number',
    'Password': 'Password',
    'Confirm Password': 'Confirm Password',
    'Save': 'Save',
    'Users Mangement': 'Users Mangement',
    'Add Admins': 'Add Admins',
    'Admin Listings': 'Admin Listings',
    'Status': 'Status',
    'Search': 'Search',
    'Edit': 'Edit',
    'View': 'View',
    'Email': 'Email',
    'Phone': 'Phone',
    'Created': 'Created',
    'User Name': 'User Name',
    'Boat Name': 'Boat Name',
    'Owner Name': 'Owner Name',
    'Packages': 'Packages',
    'City': 'City',
    'Boat Owner Listings': 'Boat Owner Listings',
    'Add Owner': 'Add Owner',

    'Bookings': 'Bookings',
    'Bookings Listing': 'Bookings Listing',
    'Active Bookings': 'Active Bookings',
    'Past Bookings': 'Past Bookings',
    'Accept': 'Accept',
    'Reject': 'Reject',
    'Check In': 'Check In',
    'Check Out': 'Check Out',

    'Owner Info': 'Owner Info',
    'User Details': 'User Details',
    'Booking Details': 'Booking Details',
    'Booking ID': 'Booking ID',
    'Boat ID': 'Boat ID',
    'Payment Method': 'Payment Method',
    'Price of Booking': 'Price of Booking',
    'Order Amount': 'Order Amount',
    'MyBoat Commission': 'MyBoat Commission',
    'VAT': 'VAT',
    'Total Amount': 'Total Amount',
    'Photos': 'Photos',
    'Accept Booking': 'Accept Booking',
    'Reject Booking': 'Reject Booking',

    'Pending': 'Pending',
    'Confirmed': 'Confirmed',
    'Cancelled': 'Cancelled',
    'Checked In': 'Checked In',
    'Completed': 'Completed',
    'In Progress': 'In Progress',

    'Delete': 'Delete',
    'Category Listing': 'Category Listing',
    'Add Category': 'Add Category',
    'City Listing': 'City Listing',
    'Add City': 'Add City',

    'License No': 'License No',
    'Last Service Report': 'Last Service Report',
    'Service Report': 'Service Report',
    'Last Anti Fouling Date': 'Last Anti Fouling Date',
    'Anti fouling': 'Anti fouling',
    'Paint': 'Paint',
    'GPS': 'GPS',
    'USB Socket': 'USB Socket',
    'Bimini': 'Bimini',
    'Polish': 'Polish',
    'Anti Fouling': 'Anti Fouling',
    'Washroom': 'Washroom',
    'Cleanliness': 'Cleanliness',
    'Life Jackets': 'Life Jackets',
    'Fire Extinguisher': 'Fire Extinguisher',
    'Air Conditioner': 'Air Conditioner',
    'Rooms': 'Rooms',
    'Salon': 'Salon',
    'Smoke': 'Smoke',
    'Gearbox Sound': 'Gearbox Sound',
    'Depth Sounder': 'Depth Sounder',
    'Back': 'Back',
    'Next': 'Next',
    'Submit': 'Submit',
    'Boat Documents': 'Boat Documents',
    'Upload Document': 'Upload Document',

    'Category': 'Category',
    'Boat Brand': 'Boat Brand',
    'Model': 'Model',
    'Engine Brand/ Model': 'Engine Brand/ Model',
    'Engine Brand': 'Engine Brand',
    'Year': 'Year',
    'Select Year': 'Select Year',
    'Location': 'Location',
    'Latitude & Longitude': 'Latitude & Longitude',
    'Add Latitude & Longitude': 'Add Latitude & Longitude',
    'Service & Maintenance Time': 'Service & Maintenance Time',
    'Select Time': 'Select Time',
    'Boat Manager': 'Boat Manager',
    'Select Boat Manager': 'Select Boat Manager',
    'Boat Length': 'Boat Length',
    'General Boat Description': 'General Boat Description',
    'Enter General Boat Description': 'Enter General Boat Description',
    'Boat Pictures': 'Boat Pictures',
    'Upload Pictures': 'Upload Pictures',
    'Upload Image': 'Upload Image',

    'Update Boat': 'Update Boat',
    'Add Boat': 'Add Boat',
    'View Boat': 'View Boat',
    'General Boat Information': 'General Boat Information',
    'Owner Information': 'Owner Information',
    'Rates & Packages': 'Rates & Packages',
    'Boat Condition': 'Boat Condition',
    'Upload Documents': 'Upload Documents',
    'Boat Condition & Features': 'Boat Condition & Features',


    'Owner Type': 'Owner Type',
    'ID No': 'ID No',
    'Certificate of Owner Registration': 'Certificate of Owner Registration',
    'Country': 'Country',
    'Unit': 'Unit',
    'Street': 'Street',
    'District': 'District',
    'Structure No': 'Structure No',
    'Please enter valid Mobile Number': 'Please enter valid Mobile Number',
    'Account Information': 'Account Information',
    'Bank Name': 'Bank Name',
    'Branch': 'Branch',
    'IBAN': 'IBAN',


    'Pricing': 'Pricing',
    'Slot Time': 'Slot Time',
    'Select Price (SAR)': 'Select Price (SAR)',
    'Enter Price in SAR': 'Enter Price in SAR',
    'Enter Percentage (0-100%)': 'Enter Percentage (0-100%)',
    'Package Name': 'Package Name',
    'Added Packages': 'Added Packages',

    'Boats': 'Boats',
    'Add Boats': 'Add Boats',
    'Boat Listing': 'Boat Listing',
    'Select Status': 'Select Status',
    'Select Boat Manager': 'Select Boat Manager',
    'Search': 'Search',
    'Approve': 'Approve',
    'Reject': 'Reject',
    'View Rejection Notes': 'View Rejection Notes',
    'Submit For Approval': 'Submit For Approval',
    'Listing': 'Listing',
    'Pending Checklist': 'Pending Checklist',
    'Rejected': 'Rejected',
    'Draft': 'Draft',
    'Manager Name': 'Manager Name',
    'Location': 'Location',
    'Rejection Note': 'Rejection Note',
    'Rejection Note...': 'Rejection Note...',

    'Packages Details': 'Packages Details',
    'Package Name': 'Package Name',
    'Enter Package Name': 'Enter Package Name',
    'Package Price (SAR)': 'Package Price (SAR)',
    'Package Description': 'Package Description',
    'Enter Package Description': 'Enter Package Description',
    'Upload Image': 'Upload Image',
    'Enter Package Items': 'Enter Package Items',
    'Added Package Items': 'Added Package Items',
    'Add Package': 'Add Package',
    'Price': 'Price',
    'Description': 'Description',
    'Packages Listing': 'Packages Listing',
    'Package Items': 'Package Items',


    'Category Details': 'Category Details',
    'Category Name (English)': 'Category Name (English)',
    'Category Name (Arabic)': 'Category Name (Arabic)',
    'Category Description': 'Category Description',
    'Enter Category Description': 'Enter Category Description',
    'Minimum Time Slot': 'Minimum Time Slot',
    'Enter Minimum Time Slot': 'Enter Minimum Time Slot',
    'Booking Lead Time': 'Booking Lead Time',
    'Enter Booking Lead Time': 'Enter Booking Lead Time',

    'City Details': 'City Details',
    'Latitude': 'Latitude',
    'Longitude': 'Longitude',


    'Contact Details': 'Contact Details',
    'Calling Number': 'Calling Number',
    'Enter Calling Number': 'Enter Calling Number',
    'Email Address': 'Email Address',
    'Enter Email Address': 'Enter Email Address',
    'WhatsApp Number': 'WhatsApp Number',
    'Enter WhatsApp Number': 'Enter WhatsApp Number',
    'Twitter URL': 'Twitter URL',
    'Enter Twitter URL': 'Enter Twitter URL',
    'Instagram URL': 'Instagram URL',
    'Enter Instagram URL': 'Enter Instagram URL',
    'System Parameters': 'System Parameters',

    'View Admins Details': 'View Admins Details',
    'Admin Details': 'Admin Details',
    'Admin ID': 'Admin ID',
    'Admin name': 'Admin name',
    'Mobile No': 'Mobile No',
    'Joining Date': 'Joining Date',


    'View Boat Owner': 'View Boat Owner',
    'Owner Details': 'Owner Details',
    'Bookings Listing': 'Bookings Listing',
    'Select Status': 'Select Status',
    'Search': 'Search',
    'Boat Listing': 'Boat Listing',

    'View App Users': 'View App Users',
    'App User Details': 'App User Details',
    'Update Owner': 'Update Owner',
    'Add New Owner': 'Add New Owner',
    'App Users Listings': 'App Users Listings',
    'Approved': 'Approved',
    'Under Process': 'Under Process',
    'Rejected': 'Rejected',

    'Add Cancelation Note': 'Add Cancelation Note',
    'Send your note...': 'Send your note...',
    'Cancel Booking': 'Cancel Booking',
    'Dashboard': 'Dashboard',
    'Top 10 App Users': 'Top 10 App Users',
    'Top 10 Frequently Used Boats': 'Top 10 Frequently Used Boats',


    'Total Number of Bookings': 'Total Number of Bookings',
    'Total Bookings Value': 'Total Bookings Value',
    'Total No. of Active Boats': 'Total No. of Active Boats',
    'No. of Boats Pending Approval': 'No. of Boats Pending Approval',
    'Completed Orders': 'Completed Orders',
    'New Orders': 'New Orders',
    'Pending Orders': 'Pending Orders',
    'Rejected Orders': 'Rejected Orders',

    'Booking Value': 'Booking Value',
    'Total Bookings': 'Total Bookings',
    'Booking Count': 'Booking Count',
    'Back to home': 'Back to home',
    'we could not find the page you are looking for': 'we could not find the page you are looking for',


    'Finance': 'Finance',
    'Finance Listing': 'Finance Listing',
    'Select Booking Date': 'Select Booking Date',
    'Print': 'Print',
    'Finance Details': 'Finance Details',
    'Order Amount': 'Order Amount',
    'VAT': 'VAT',
    'Total Amount': 'Total Amount',
    'MyBoat Commission': 'MyBoat Commission',
    'Paid Invoices': 'Paid Invoices',
    'No. of Requests Pending': 'No. of Requests Pending',
    'Transaction ID': 'Transaction ID',
    'Booking ID': 'Booking ID',
    'Payment Method': 'Payment Method',
    'Price of Booking': 'Price of Booking',
    'Requested Date': 'Requested Date',
    'Booking Date': 'Booking Date',

    'Forgot': 'Forgot',
    'Password?': 'Password?',
    'Please enter your Email Address to reset your password': 'Please enter your Email Address to reset your password',
    'Email or Phone': 'Email or Phone',

    'Welcome to': 'Welcome to',
    'MyBoat': 'MyBoat',
    'Please enter your username and password to log in': 'Please enter your username and password to log in',
    'Forgot Password?': 'Forgot Password?',
    'Sign In': 'Sign In',
    'My Profile': 'My Profile',
    'Profile': 'Profile',

    'Enter': 'Enter',
    'One Time Passcode': 'One Time Passcode',
    'Passcode sent to you on email address': 'Passcode sent to you on email address',
    'Please enter your 4 digit One Time': 'Please enter your 4 digit One Time',
    'Enter 4-Digit Code': 'Enter 4-Digit Code',
    'Resend Code': 'Resend Code',
    'Reset': 'Reset',
    'Please enter your New Password and Confirm Password': 'Please enter your New Password and Confirm Password',
    'to reset your password': 'to reset your password',
    'New Password': 'New Password',
    'Confirm New Password': 'Confirm New Password',
    'Reset Password': 'Reset Password',

    'View Finance': 'View Finance',
    'Print Invoice': 'Print Invoice',
    'Username': 'Username',
}
